import { Form, Link, useLoaderData, useLocation } from '@remix-run/react';
import { loader as rootLoader } from '~/root';

export default function CookieConsentBanner({
  hideOnRoutes,
}: {
  hideOnRoutes?: string[];
}) {
  const { cookieConsent } = useLoaderData<typeof rootLoader>();
  const location = useLocation();

  if (cookieConsent) return null;
  if (hideOnRoutes?.some((route) => location.pathname.startsWith(route)))
    return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 flex items-center justify-center gap-2 border-t bg-white/75 p-2 text-xs md:gap-12">
      <p>
        This website uses cookies to improve your web experience. By visiting
        this website, you agree to the use of cookies.{' '}
        <Link to="/cookie-policy" className="text-theme-blue-dark">
          Learn More.
        </Link>
      </p>
      <Form method="post">
        <input type="hidden" name="_action" value="cookie-consent" />
        <input type="hidden" name="cookieConsent" value="true" />
        <input type="hidden" name="redirect" value={location.pathname} />

        <button
          type="submit"
          className="whitespace-nowrap border bg-white px-8 py-1"
        >
          Got it!
        </button>
      </Form>
    </div>
  );
}
