import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import {
  json,
  redirect,
  type ActionFunctionArgs,
  type LinksFunction,
  type LoaderFunctionArgs,
} from '@remix-run/node';
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import { fetchStrapi } from '~/lib/server/api.server';
import background from './images/background-no-ellipses.webp';
import logo from './images/logo-wide.svg';
import LayoutPaddingX from './components/layout/LayoutPaddingX';
import { GlobalLoadingIndicator } from '@cardo/ui';
import { getAppUrl } from './lib/utils';
import CookieConsentBanner from './components/CookieConsentBanner';
import { cookieConsentCookie } from './lib/server/cookies.server';
import '~/styles/nprogress.css';
import '~/styles/widget-embed.css';
import '~/tailwind.css';

export const links: LinksFunction = () => {
  return [
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '57x57',
      href: '/apple-touch-icon-57x57.png',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '114x114',
      href: '/apple-touch-icon-114x114.png',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '72x72',
      href: '/apple-touch-icon-72x72.png',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '144x144',
      href: '/apple-touch-icon-144x144.png',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '60x60',
      href: '/apple-touch-icon-60x60.png',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '120x120',
      href: '/apple-touch-icon-120x120.png',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '76x76',
      href: '/apple-touch-icon-76x76.png',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '152x152',
      href: '/apple-touch-icon-152x152.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-196x196.png',
      sizes: '196x196',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-96x96.png',
      sizes: '96x96',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-32x32.png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-16x16.png',
      sizes: '16x16',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-128x128.png',
      sizes: '128x128',
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  try {
    const footer = await fetchStrapi('/footer', {
      populate: {
        socialLinks: {
          populate: {
            socialLinks: '*',
          },
        },
      },
    });

    const cookieConsent =
      (await cookieConsentCookie.parse(request.headers.get('Cookie'))) || false;

    return {
      footer,
      ENV: {
        NODE_ENV: process.env.NODE_ENV,
        SENTRY_DSN: process.env.SENTRY_DSN,
        SENTRY_RELEASE: process.env.SENTRY_RELEASE,
        APP_URL: getAppUrl(),
        AUTHENTICATED_APP_URL: process.env.AUTHENTICATED_APP_URL,
        MEDIA_S3_URL: process.env.MEDIA_S3_URL,
        MEDIA_CLOUDFRONT_URL: process.env.MEDIA_CLOUDFRONT_URL,
        ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID,
        ALGOLIA_SEARCH_API_KEY: process.env.ALGOLIA_SEARCH_API_KEY,
      },
      cookieConsent,
    };
  } catch (err) {
    console.error(err);

    if (err?.name === 'FetchError') {
      if (err?.code === 'ECONNREFUSED') {
        throw json(
          {
            NODE_ENV: process.env.NODE_ENV,
            SENTRY_DSN: process.env.SENTRY_DSN,
            SENTRY_RELEASE: process.env.SENTRY_RELEASE,
          },
          { status: 500, statusText: 'Network Error' }
        );
      }
    }

    throw new Response('Internal Server Error', {
      status: 500,
      statusText: 'Internal Server Error',
    });
  }
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const _action = formData.get('_action');

  if (_action === 'cookie-consent') {
    const cookieConsent = formData.get('cookieConsent') === 'true';
    const redirectPath = String(formData.get('redirect') || '/');

    return redirect(redirectPath, {
      headers: {
        'Set-Cookie': await cookieConsentCookie.serialize(cookieConsent),
      },
    });
  }
}

function App() {
  const loaderData = useLoaderData<typeof loader>();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="application-name" content="Cardonomics" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta
          name="msapplication-square70x70logo"
          content="/mstile-70x70.png"
        />
        <meta
          name="msapplication-square150x150logo"
          content="/mstile-150x150.png"
        />
        <meta
          name="msapplication-wide310x150logo"
          content="/mstile-310x150.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="/mstile-310x310.png"
        />
        <Meta />
        <Links />
      </head>
      <body className="overflow-x-hidden">
        <GlobalLoadingIndicator />
        <CookieConsentBanner hideOnRoutes={['/go']} />
        <Outlet />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(loaderData.ENV)}`,
          }}
        />
        {process.env.NODE_ENV === 'production' && (
          <script
            defer
            src="/stats/js/script.js"
            data-api="/stats/api/event"
            data-domain="cardonomics.com"
          ></script>
        )}
        <Scripts />
      </body>
    </html>
  );
}

export default process.env.NODE_ENV === 'production' ? withSentry(App) : App;

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  let errorMessage = 'Unknown error';
  let envData;

  if (error.statusText === 'Network Error') {
    errorMessage = error.statusText;
    envData = error.data;
  } else if (isRouteErrorResponse(error)) {
    errorMessage = error.data?.message ?? error.statusText;
  }

  return (
    <html>
      <head>
        <title>Cardonomics - Error</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex h-screen w-screen flex-col">
          <LayoutPaddingX className="bg-white pb-8 shadow-md">
            <header className="flex flex-col pt-8 sm:pt-10">
              <nav className="flex items-center justify-between">
                <div>
                  <Link to="/">
                    <img
                      src={logo}
                      alt="cardonomics logo"
                      width={252}
                      height={35}
                    />
                  </Link>
                </div>
              </nav>
            </header>
          </LayoutPaddingX>
          <div className="flex w-full flex-grow items-center justify-center p-5">
            <div className="absolute left-0 right-0 top-0 z-[-1] h-full w-full overflow-hidden">
              <img
                src={background}
                alt="background"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="max-w-full overflow-auto rounded-lg border-2 border-rose-700 bg-white px-6 py-12 shadow-md sm:px-20">
              <h1 className="mb-8">{errorMessage}</h1>
              <h4 className="mb-8">We{"'"} re working on it.</h4>
              {process.env.NODE_ENV !== 'development' && (
                <p>
                  Please email{' '}
                  <a href="mailto:admin@cardonomics.com">
                    admin@cardonomics.com
                  </a>{' '}
                  with any urgent questions.
                </p>
              )}
            </div>
          </div>
        </div>
        {envData && (
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify({
                NODE_ENV: envData.NODE_ENV,
                SENTRY_DSN: envData.SENTRY_DSN,
                SENTRY_RELEASE: envData.SENTRY_RELEASE,
              })}`,
            }}
          />
        )}
        <Scripts />
      </body>
    </html>
  );
}
