/* eslint-disable import/namespace */
import { useNavigation } from '@remix-run/react';
import { useEffect } from 'react';
import * as NProgress from 'nprogress';

export function GlobalLoadingIndicator() {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state === 'loading') NProgress.start();
    if (navigation.state === 'idle') NProgress.done();
  }, [navigation.state]);

  return <></>;
}
